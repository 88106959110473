import "./App.css";
import React from "react";
import { BarChart, Target, Users } from "lucide-react";
import Nav from "./Nav";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portfolio from "./Portfolio";
// comment

const CTOLandingPage = () => {
  const [hov, setHov] = React.useState(null);

  const data = {
    stats: [
      {
        n: "300M+",
        l: "Daily Transactions Handled",
        g: "from-blue-500 to-indigo-500",
      },
      { n: "10+", l: "Years Experience", g: "from-indigo-500 to-purple-500" },
      { n: "£15M", l: "Contract Value Led", g: "from-purple-500 to-pink-500" },
      { n: "1M+", l: "Users Impacted", g: "from-pink-500 to-rose-500" },
    ],
    competencies: [
      {
        i: <Target className="h-8 w-8" />,
        t: "Strategic Vision",
        items: [
          "Technology roadmap development",
          "Digital transformation leadership",
          "Innovation strategy",
          "Business-technology alignment",
        ],
        g: "from-blue-500 to-indigo-500",
      },
      {
        i: <BarChart className="h-8 w-8" />,
        t: "Delivery Excellence",
        items: [
          "High-performance architecture",
          "Scalable infrastructure design",
          "AI/ML implementation",
          "Security & compliance",
        ],
        g: "from-indigo-500 to-purple-500",
      },
      {
        i: <Users className="h-8 w-8" />,
        t: "Team Leadership",
        items: [
          "Technical team building",
          "Engineering culture development",
          "Talent acquisition & retention",
          "Mentorship & growth",
        ],
        g: "from-purple-500 to-pink-500",
      },
    ],
    achievements: [
      {
        t: "Technical Vision & Execution",
        d: "Successfully consolidated 8 siloed platforms into a unified, high-availability portal serving nationwide operations.",
      },
      {
        t: "Team & Culture Building",
        d: "Scaled technical operations from 10 to 90 employees while maintaining high performance and innovation.",
      },
      {
        t: "Innovation Leadership",
        d: "Pioneered AI initiatives including a sales forecasting platform and led breakthrough AI hackathon for Police Scotland.",
      },
      {
        t: "Business Impact & Growth",
        d: "Delivered solutions generating £500k annual savings and secured multi-million pound contracts.",
      },
    ],
  };

  const GradText = ({ children, cls = "" }) => (
    <span
      className={`bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent ${cls}`}
    >
      {children}
    </span>
  );

  const Section = ({ children, className = "" }) => (
    <div className={`py-24 ${className}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50">
      <Nav />
      <header className="relative overflow-hidden bg-gradient-to-r from-indigo-900 via-purple-900 to-indigo-900 text-white">
        <div className="absolute inset-0">
          <div className="absolute inset-0 opacity-10"></div>
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/30 via-purple-600/30 to-pink-600/30 backdrop-blur-3xl"></div>
        </div>
        <div className="max-w-7xl mx-auto py-32 px-4 sm:px-6 lg:px-8 relative">
          <div className="max-w-3xl">
            <h1 className="text-7xl font-bold mb-6 bg-gradient-to-r from-white via-indigo-200 to-white bg-clip-text text-transparent">
              Simon Stead
            </h1>
            <p className="text-4xl font-light mb-8 bg-gradient-to-r from-indigo-100 to-purple-100 bg-clip-text text-transparent">
              Transformational Technology Leader
            </p>
            <p className="text-xl text-indigo-200 leading-relaxed max-w-2xl mb-8">
              Bridging business strategy and technical innovation to drive
              growth and digital transformation. Proven track record of scaling
              teams, modernizing infrastructure, and delivering high-impact
              solutions.
            </p>
          </div>
        </div>
      </header>

      <Section>
        <div className="max-w-3xl relative p-12">
          <div className="absolute -left-4 top-0 w-1 h-full bg-gradient-to-b from-indigo-500 to-purple-500 rounded-full"></div>
          <h2 className="text-5xl font-bold mb-8">
            <GradText>Why Work With Me?</GradText>
          </h2>
          <div className="space-y-6 text-xl text-gray-700">
            <p>
              As a hands-on technical leader with over a decade of experience, I
              specialize in turning complex technical challenges into strategic
              business advantages. My approach combines deep technical expertise
              with business acumen and people leadership.
            </p>
            <p>
              Whether you're scaling your startup, modernizing legacy systems,
              or driving innovation in an enterprise environment, I bring the
              experience and vision to help you succeed.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <h2 className="text-5xl font-bold mb-20 text-center">
          <GradText>Impact & Scale</GradText>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 auto-rows-fr">
          {data.stats.map((s, i) => (
            <div
              key={i}
              className="relative group h-full flex-1"
              onMouseEnter={() => setHov(i)}
              onMouseLeave={() => setHov(null)}
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${
                  s.g
                } rounded-xl transform rotate-1 transition-all duration-300 ${
                  hov === i
                    ? "opacity-100 scale-105 blur-sm"
                    : "opacity-75 scale-100"
                }`}
              ></div>
              <div
                className={`relative bg-white p-8 rounded-xl shadow-xl backdrop-blur-sm transform transition-all duration-300 h-full flex flex-col justify-center ${
                  hov === i ? "-rotate-1 translate-y-1" : "-rotate-2"
                }`}
              >
                <div
                  className={`text-5xl font-bold bg-gradient-to-r ${s.g} bg-clip-text text-transparent mb-4`}
                >
                  {s.n}
                </div>
                <div className="text-gray-600 font-medium text-lg">{s.l}</div>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <Section>
        <div className="max-w-3xl relative ml-auto p-12">
          <div className="absolute -right-4 top-0 w-1 h-full bg-gradient-to-b from-purple-500 to-indigo-500 rounded-full"></div>
          <h2 className="text-5xl font-bold mb-8 text-right">
            <GradText>CTO Experience</GradText>
          </h2>
          <div className="space-y-6 text-xl text-gray-700">
            <p className="text-right">
              As Chief Technology Officer at Quiltt, I spearheaded the
              development of an AI-driven sales forecasting and analytics
              platform from conception to delivery. I personally architected and
              built their machine learning capabilities using Python and
              XGBoost, while implementing a highly available Kubernetes platform
              on AWS.
            </p>
            <p className="text-right">
              This hands-on technical leadership role showcased my ability to
              balance strategic vision with technical execution - from designing
              scalable microservices architectures to implementing robust CI/CD
              pipelines and automated testing infrastructure. The platform's
              success demonstrated my capability to deliver complex AI solutions
              while maintaining high availability and performance at scale.
            </p>
          </div>
        </div>
      </Section>

      <Section className="relative overflow-hidden">
        <h2 className="text-5xl font-bold mb-16 text-center">
          <GradText>Strategic Leadership Areas</GradText>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 auto-rows-fr">
          {data.competencies.map((c, i) => (
            <div
              key={i}
              className="relative group perspective-1000 h-full flex"
            >
              <div
                className={`absolute inset-0 bg-gradient-to-r ${c.g} rounded-xl opacity-75 blur transform group-hover:scale-105 group-hover:opacity-100 transition-all duration-300`}
              ></div>
              <div className="relative bg-white/90 backdrop-blur-sm p-8 rounded-xl shadow-xl transform transition-all duration-300 group-hover:translate-y-1 flex-1 flex flex-col">
                <div className="flex items-center mb-8">
                  <div
                    className={`p-3 rounded-lg bg-gradient-to-r ${c.g} text-white transform transition-transform group-hover:scale-110`}
                  >
                    {c.i}
                  </div>
                  <h3 className="text-xl font-semibold ml-4">{c.t}</h3>
                </div>
                <ul className="space-y-4 text-gray-600 flex-1">
                  {c.items.map((item, j) => (
                    <li key={j} className="flex items-start group/item">
                      <span
                        className={`mr-2 text-transparent bg-gradient-to-r ${c.g} bg-clip-text transform transition-all duration-300 group-hover/item:scale-110`}
                      >
                        •
                      </span>
                      <span className="transform transition-all duration-300 group-hover/item:translate-x-1">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Section>

      <Section>
        <div className="max-w-3xl relative p-12">
          <div className="absolute -left-4 top-0 w-1 h-full bg-gradient-to-b from-indigo-500 to-purple-500 rounded-full"></div>
          <h2 className="text-5xl font-bold mb-8">
            <GradText>Product & People Leadership</GradText>
          </h2>
          <div className="space-y-6 text-xl text-gray-700">
            <p>
              I've scaled and led large engineering organizations, growing AND
              Digital's Manchester office from 10 to 90 employees, including
              managing 80+ engineers across multiple client engagements. As
              Principal Engineer, I established engineering best practices,
              mentoring programs, and built a culture of technical excellence
              that attracted and retained top talent.
            </p>
            <p>
              My product leadership has transformed business units through
              data-driven development and user-centered design. From leading
              Scottish Widows' pensions platform development to delivering
              Boohoo's £500k efficiency program, I consistently align technical
              capabilities with commercial outcomes. I've implemented agile
              processes, facilitated discovery workshops, and enhanced team
              productivity through improved refinement and delivery processes.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <h2 className="text-5xl font-bold mb-16 text-center">
          <GradText>Proven Track Record</GradText>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 auto-rows-fr">
          {data.achievements.map((a, i) => (
            <div
              key={i}
              className="group relative perspective-1000 h-full flex"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-300 blur-xl"></div>
              <div className="relative bg-white/90 backdrop-blur-sm border border-gray-100/50 rounded-xl p-8 shadow-lg transform transition-all duration-300 group-hover:shadow-2xl group-hover:translate-y-1 flex-1 flex flex-col">
                <h3 className="text-2xl font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent mb-6">
                  {a.t}
                </h3>
                <p className="text-gray-600 leading-relaxed flex-1">{a.d}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      <footer className="relative py-32 overflow-hidden bg-gradient-to-r from-indigo-900 via-purple-900 to-indigo-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative text-center">
          <h2 className="text-5xl font-bold text-white mb-8">
            Ready to Transform Your Technical Strategy?
          </h2>
          <p className="text-xl text-indigo-200 mb-12 max-w-2xl mx-auto">
            Available for CTO and fractional CTO roles. Let's discuss how I can
            help shape your technology future.
          </p>
          <div className="space-x-6">
            <a
              href="mailto:steady@steadtec.com"
              className="inline-block bg-white text-indigo-600 px-8 py-4 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 text-lg font-medium hover:-translate-y-1"
            >
              Start the Conversation
            </a>
            <a
              href="/portfolio"
              className="inline-block bg-indigo-700 text-white px-8 py-4 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 text-lg font-medium hover:-translate-y-1"
            >
              View Portfolio
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<CTOLandingPage />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
