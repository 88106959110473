import React, { useState } from "react";
import { Menu } from "lucide-react";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white backdrop-blur-md shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <a
            href="/"
            className="text-xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"
          >
            Simon Stead
          </a>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            <a
              href="/"
              className="text-gray-700 hover:text-indigo-600 transition-colors"
            >
              Home
            </a>
            <a
              href="/portfolio"
              className="text-gray-700 hover:text-indigo-600 transition-colors"
            >
              Portfolio
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-indigo-600 transition-colors"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white backdrop-blur-md">
            <a
              href="/"
              className="block px-3 py-2 text-gray-700 hover:text-indigo-600 transition-colors"
            >
              Home
            </a>
            <a
              href="/portfolio"
              className="block px-3 py-2 text-gray-700 hover:text-indigo-600 transition-colors"
            >
              Portfolio
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;
